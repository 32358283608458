/**
 * manage the state of a user's preferences of various items that could display differently when interacted with
 * keep track of active interactions across the application, for example:
 *
 * compare toggle,
 * compare drawer,
 * recent searches toggle
 */
import {
    createSelector,
    DuckSelector,
    objectDuckCreator,
} from '@atc/modular-redux';

import {
    BrowserStorage,
} from 'atc-js';

const recentSearchToggleCache = new BrowserStorage('RECENT_SEARCH_CHIPS', {
    persist: true,
});

const userPreferencesDuck = objectDuckCreator({
    store: 'userPreferences',
    initialState: {
        compareToggle: false,
        showDrawer: false,
        showRecentSearches: true,
        showShareListingsModal: false,
    },
}).extend({
    creators: () => ({
        setShowRecentSearches: (show) => async (dispatch) => {
            recentSearchToggleCache.setItem({
                showRecentSearches: show,
            });

            await dispatch(userPreferencesDuck?.creators?.setKeys({
                showRecentSearches: show,
            }));
        },
    }),
    selectors: {
        getCompareToggle: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).compareToggle),
        getShareListingsModal: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showShareListingsModal),
        getShowDrawer: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showDrawer),
        getShowRecentSearches: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ showRecentSearches }) => (recentSearchToggleCache.getItem()?.showRecentSearches !== undefined
                ? recentSearchToggleCache.getItem()?.showRecentSearches : showRecentSearches),
        )),
    },
});

export default userPreferencesDuck;
