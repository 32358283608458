import React from 'react';

import dynamic from 'next/dynamic';

import { useBrand } from 'reaxl-brand';

const AtcTheme = dynamic(() => import('@/theme/AtcTheme'));
const KbbTheme = dynamic(() => import('@/theme/KbbTheme'));
const FordTheme = dynamic(() => import('@/theme/FordTheme'));
const DemoTheme = dynamic(() => import('@/theme/DemoTheme'));
const PreviewAtcTheme = dynamic(() => import('@/theme/PreviewAtcTheme'));

export default function ThemeLoader() {

    const { brand } = useBrand();

    switch (brand) {
        case 'kbb': {
            return <KbbTheme />;
        }

        case 'ford': {
            return <FordTheme />;
        }

        case 'demo': {
            return <DemoTheme />;
        }

        case 'previewAtc': {
            return <PreviewAtcTheme />;
        }

        case 'atc':
        default: {
            return <AtcTheme />;
        }
    }
}
