import { useEffect } from 'react';

import { usePageEvent } from '@bonnet/next/use-page-event';

import { getActiveExperiments } from 'reaxl-optimizely';

export default function useNewRelicClientConfiguration({ reqId } = {}) {

    const { onPageEnter } = usePageEvent('newrelic');
    const activeExperiments = getActiveExperiments();

    onPageEnter(() => {
        if (typeof window !== 'undefined') {
            if (window.newrelic && window.newrelic.setCustomAttribute) {
                // set newrelic attributes whenever a client route is loaded
                window.newrelic.setCustomAttribute('location', window.location && window.location.href);
                window.newrelic.addToTrace('location', window.location && window.location.href);
            }
        }
    });

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.newrelic && window.newrelic.setCustomAttribute) {
                window.newrelic.setCustomAttribute('ATC_REQID', reqId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reqId]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (activeExperiments && window.newrelic && window.newrelic.setCustomAttribute) {
                window.newrelic.setCustomAttribute('experiments', activeExperiments.toString());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeExperiments]);

}
