import React, { useMemo } from 'react';

import { useStore } from 'react-redux';

import getConfig from 'next/config';

import { withBonnetApp } from '@bonnet/next/app';
import { withCtxMiddleware } from '@bonnet/next/ctx-middleware';
import { setReduxDefaultMiddlewareOptions } from '@bonnet/next/redux';

import atcId from '@atc/bonnet-ctx-atc-id';
import withBrand from '@atc/bonnet-ctx-brand';
import parseSeo from '@atc/bonnet-ctx-parse-seo';
import userLocation from '@atc/bonnet-ctx-user-location';

import { ReaxlConfigurationContext } from 'reaxl';
import { BrandProvider } from 'reaxl-brand';
import {
    Provider as FeatureFlagsContextProvider,
} from 'reaxl-features';
import {
    addOptimizelyDynamicConfig,
    OptimizelyPageProvider,
} from 'reaxl-optimizely';

import {
    addAtcReqId,
    getConfigs,
    withCPOcontent,
    withFeatures,
    withKBBPageData,
    withSeoDecodedPath,
} from '@/context-middleware';

import {
    activeInteractionDuck,
    atcIdDuck,
    birfDuck,
    brandDuck,
    cbhVehicleInterestsDuck,
    compareListingsDuck,
    consumerBrowsingHistoryDuck,
    consumerRatingsDuck,
    cpoContentDuck,
    currentPageNameDuck,
    eLotResultsDuck,
    globalConfigsDuck,
    interestRateDuck,
    inventoryDuck,
    kbbPageDataDuck,
    kbbVRSDataDuck,
    ownersDuck,
    paymentsDuck,
    preorderDuck,
    preorderOwnersDuck,
    recentSearchDuck,
    requestParamsDuck,
    savedInventoryDuck,
    shareEmailSubmissionDuck,
    shareTextSubmissionDuck,
    userAgentDuck,
    userDuck,
    userPreferencesDuck,
} from '@/ducks';

import useNewRelicClientConfiguration from '@/newrelic/useNewRelicClientConfiguration';

import ThemeLoader from '@/theme/ThemeLoader';

import BaseContainer from '@/containers/BaseContainer';
import FooterContainer from '@/containers/FooterContainer';
import OptimizelyEdgeContainer from '@/containers/global/OptimizelyEdgeContainer';
import OptimizelyAnalyticsListenerContainer from '@/containers/OptimizelyAnalyticsListenerContainer';
import ScreenReaderContainer from '@/containers/ScreenReaderContainer';

import AppFooter from '@/components/AppFooter';
import AppHeader from '@/components/AppHeader';
import ReaxlNextLink from '@/components/ReaxlNextLink';

if (typeof window !== 'undefined') {

    if (!global.thirdPartyDeferPromise) {
        global.thirdPartyDeferPromise = new Promise((res) => {
            if (document?.readyState === 'complete') {
                res();
            } else {
                window.addEventListener('load', res, { once: true });
            }
        });
    }
}

// Disable redux middleware performance checks
// TODO: Re-enable and fix these issues!
setReduxDefaultMiddlewareOptions({
    immutableCheck: false,
    serializableCheck: false,
});

function App(props) {

    const {
        ATC_REQID,
        Component,
        featureFlags,
        brand = 'atc',
        optimizelyDynamicConfig = {},
        pageProps,
    } = props;

    // support Cypress Redux tests
    const store = useStore();
    if (typeof window !== 'undefined' && window.Cypress) {
        window.store = store;
    }

    useNewRelicClientConfiguration({ reqId: ATC_REQID });

    const reaxlConfiguration = useMemo(() => ({
        // This is used to allow all reaxl/Links to use NextJS' link component
        LinkComponent: ReaxlNextLink,
    }), []);

    return (
        <ReaxlConfigurationContext.Provider value={reaxlConfiguration}>

            <FeatureFlagsContextProvider flags={featureFlags}>
                <OptimizelyPageProvider
                    disabled={process.env.DISABLE_OPTIMIZELY}
                    optimizelyDynamicConfig={optimizelyDynamicConfig}
                >
                    <BrandProvider brand={brand}>
                        <ThemeLoader />
                        <OptimizelyAnalyticsListenerContainer />
                        <OptimizelyEdgeContainer />
                        <ScreenReaderContainer />
                        <AppHeader />
                        <Component {...pageProps} />
                        <AppFooter />
                        <FooterContainer isBot={pageProps.isBot} />
                        <BaseContainer thirdPartyScripts={{}} />
                    </BrandProvider>
                </OptimizelyPageProvider>
            </FeatureFlagsContextProvider>
        </ReaxlConfigurationContext.Provider>
    );
}

App.getInitialProps = async (appCtx) => {
    const { ctx } = appCtx;
    const { publicRuntimeConfig = {} } = getConfig();
    const { environment } = publicRuntimeConfig;

    // apply context middleware
    await withCtxMiddleware([
        withBrand(),
        withSeoDecodedPath(),
        parseSeo(),
        withFeatures({ environment }),
        atcId(),
        addAtcReqId(),
        getConfigs(),
        userLocation({
            // disable client-side geolocation
            useGeolocation: false,
            userDuck,
        }),
        addOptimizelyDynamicConfig({ environment }),
        withCPOcontent(),
        withKBBPageData(),
    ], ctx);

    const features = {
        config: { ...ctx.data.configFeatures },
    };

    // NEXTJS CLIENT APP RENDER FIXES
    // nextjs _app getInitialProps is run on each client page load unlike previous bonnet versions
    // Any ctx-middleware that runs on the server only and and sets data that is then passed down as a prop
    // to the _app component needs to be remedied to work on client runs

    // client page loads are missing the optimizelyDynamicConfig data
    // only push this to redux if a value is present to prevent
    // undefined values from being pushed to state
    if (ctx.data.ATC_ID) {
        ctx.store.dispatch(atcIdDuck.creators.setString(ctx.data.ATC_ID));
    }

    return {
        brand: ctx.data.brand,
        isBot: ctx.data.isBot,
        environment,
        featureFlags: features,
        optimizelyDynamicConfig: ctx.data.optimizelyDynamicConfig,
        ATC_ID: ctx.data.ATC_ID,
        ATC_REQID: ctx.data.ATC_REQID,
    };
};

const { BonnetAppComponent } = withBonnetApp(App, {
    reducers: [
        atcIdDuck,
        birfDuck,
        brandDuck,
        userPreferencesDuck,
        consumerBrowsingHistoryDuck,
        cbhVehicleInterestsDuck,
        compareListingsDuck,
        consumerRatingsDuck,
        cpoContentDuck,
        currentPageNameDuck,
        eLotResultsDuck,
        globalConfigsDuck,
        inventoryDuck,
        interestRateDuck,
        kbbPageDataDuck,
        kbbVRSDataDuck,
        ownersDuck,
        paymentsDuck,
        preorderDuck,
        preorderOwnersDuck,
        recentSearchDuck,
        requestParamsDuck,
        savedInventoryDuck,
        shareEmailSubmissionDuck,
        shareTextSubmissionDuck,
        userAgentDuck,
        userDuck,
        activeInteractionDuck,
    ],
});

export default BonnetAppComponent;
