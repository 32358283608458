import React from 'react';

import Link from 'next/link';

import { removeBasePath } from '@bonnet/next/base-path';

// Next's Link Component is only designed to work with SPA transitions.
// We need to generate a native anchor tag if we determine that the destination is to be
// outside of the application
export default function ReaxlNextLink(props) {

    if (process.env.NODE_ENV === 'development') {
        if (!props.href) {
            // eslint-disable-next-line no-console
            console.log(`NextJS Link requires href but none is defined.  Other props passed: ${JSON.stringify(props)}`);
        }
    }

    const isExternal = [
        // custom defined external targets
        (testProps) => testProps.external,
        // specified targers
        (testProps) => testProps.target,
        // tel or mailto or https hrefs
        (testProps) => /^\w+:/.test(testProps.href),
        // protocoless hrefs
        (testProps) => /^\/\//.test(testProps.href),
        // ensure that the current href is a /cars-for-sale link
        (testProps) => !testProps.href.startsWith(process.env.BASE_PATH),
    ].some((test) => test(props));

    if (!isExternal) {
        const nextLinkProps = { ...props };

        // NextJS Link appends basePath to each url
        // we shall remove the basePath from the href to allow
        // NextJS to do its thing with its Link component
        nextLinkProps.href = removeBasePath(props.href);

        return <Link {...nextLinkProps} />;

    }

    // eslint-disable-next-line jsx-a11y/anchor-has-content -- content should be part of props
    return <a {...props} />;
}
