import { createSelector, DuckSelector } from '@atc/modular-redux';

import { resultsDuckCreator } from 'axl-ducks';

import { inventoryDuck, ownersDuck } from '.';

const cbhVehicleInterestsDuck = resultsDuckCreator({
    store: 'cbhVehicleInterests',
    initialState: {
        activeResults: [],
    },
}).extend({
    creators: () => ({
        loadCbhVehicleInterestsResults: (results, owners) => async (dispatch, getState) => {
            dispatch(cbhVehicleInterestsDuck.creators.setActiveResults([...results]));
            dispatch(inventoryDuck.creators.addInventory([...results]));
            dispatch(ownersDuck?.creators?.addOwners([...owners]));
        },
    }),
    selectors: () => ({
        getFirstVehicle: new DuckSelector((selectors) => createSelector(
            selectors.getActiveResults,
            (activeResults) => {

                const reducedPriceVehicle = activeResults.find((result) => result.isReducedPrice);
                const greatPriceVehicle = activeResults.find((result) => result.pricingDetail?.dealIndicator === 'Great');
                const goodPriceVehicle = activeResults.find((result) => result.pricingDetail?.dealIndicator === 'Good');
                const vdpViewCount = activeResults?.[0]?.viewCount;

                // prioritize reduced price vehicles
                if (reducedPriceVehicle) {
                    return reducedPriceVehicle;
                }
                if (!reducedPriceVehicle) {

                    if (vdpViewCount > 3) {
                        return activeResults?.[0];
                    }

                    // next priority is vehicles with great price
                    if (greatPriceVehicle) {
                        return greatPriceVehicle;
                    }
                    // vehicle with good price
                    if (goodPriceVehicle) {
                        return goodPriceVehicle;
                    }

                    return activeResults?.[0];

                }

            }
        )),
    }),
});

export default cbhVehicleInterestsDuck;
