import dynamic from 'next/dynamic';

import { useBrand } from 'reaxl-brand';
import { useFeature } from 'reaxl-features';

const AtcHeader = dynamic(() => import('@/components/AtcHeader'), {});
const KBBHeader = dynamic(() => import('@/components/KBBHeader'), {});
const FordHeader = dynamic(() => import('@/components/FordHeader'), {});
const DemoHeader = dynamic(() => import('@/components/DemoHeader/index.js'), {});

export default function AppHeader() {
    const { brand, brands } = useBrand();
    const { ATC_BRAND, KBB_BRAND, FORD_BRAND, DEMO_BRAND } = brands;

    const [showWallet] = useFeature('my_wallet');

    let BrandedHeader;

    switch (brand) {
        case KBB_BRAND: {
            BrandedHeader = KBBHeader;
            break;
        }

        case FORD_BRAND: {
            BrandedHeader = FordHeader;
            break;
        }

        case DEMO_BRAND: {
            BrandedHeader = DemoHeader;
            break;
        }

        case ATC_BRAND:
        default: {
            BrandedHeader = AtcHeader;
        }
    }

    return (<BrandedHeader showWallet={showWallet} />);
}
