import {
    useEffect,
} from 'react';

import { useSelector } from 'react-redux';

import qs from 'querystring';

import _get from 'lodash/get';

import { usePageEvent } from '@bonnet/next/use-page-event';

import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';
import {
    useOptimizelyTrack,
} from 'reaxl-optimizely';

import currentPageNameDuck from '@/ducks/currentPageNameDuck';

// Map Optimizely event IDs to event value.
const eventValues = {
    vdp_pageview: 0.2,

    srp_email_lead: 21,
    vdp_email_lead: 21,

    vdp_chat: 21,
    vdp_text_dealer: 21, // VDP (Sticky form)

    dsrp_phone_call_cta: 21, // This won't ever be used because the DSRP is in the FYD microsite.
    srp_phone_call_cta: 21,
    vdp_phone_call_cta: 21,

    any_save_vehicle: 1.5,

    vdp_share_email: 1.5,
    vdp_share_text: 1.5,

    any_ucpq_lead_submission: 13,
    any_ncpq_lead_submission: 13,

    srp_external_dealer_site_cta: 4,
    vdp_external_dealer_site_cta: 4,

    vdp_wallet_pencil: 2,
    any_wallet_lead: 12.5,
    any_nds_lead: 12.5,

    stickybar_primary: 0,
    stickybar_secondary: 0,
};

const userUpdatedWallatEventValues = {
    ...eventValues,
    srp_email_lead: 33.5,
    vdp_email_lead: 33.5,
};

// Map analytics event IDs (BIRF cmp IDs) to Optimizely event IDs
const matchEvent = (pg, suffix) => ({
    comp_btn: `${pg}_${suffix}compare`,
    eml_dlr_lnk: `stickybar${suffix}`,
    my_stc: 'any_save_vehicle',
    my_stc_a: 'any_save_vehicle',
    qs_v: 'any_save_vehicle',
    qs_v_a: 'any_save_vehicle',
    snd_eml: [`${pg}_email_lead`, 'any_lead_submit'],
    slc_snd_eml: [`${pg}_email_lead`, 'any_lead_submit'], // After submitting one lead, a model prompts the user to send another. If they do then this event fires.
    cs_view_web: `${pg}_external_dealer_site_cta`,
    di_vw_wb: `${pg}_external_dealer_site_cta`,
    vw_wbst: `${pg}_external_dealer_site_cta`,
    vis_web: `${pg}_external_dealer_site_cta`,
    vw_wb_btn: `${pg}_external_dealer_site_cta`,
    cs_chat: ['vdp_chat', 'any_lead_submit'],
    ls_chat: ['vdp_chat', 'any_lead_submit'],
    shr_eml: 'vdp_share_email',
    shr_txt: 'vdp_share_text',
    nds_true: 'vdp_wallet_pencil',
    // gp: get phone number - not considered a value event currently
    call_slr: [`${pg}_phone_call_cta`, 'any_lead_submit'],
    call_slr_btn: `${pg}_phone_call_cta`,
    msg_slr: ['vdp_text_dealer', 'any_lead_submit'],
    srp_ncpq_lead_submission: ['srp_ncpq_lead_submission', 'any_ncpq_lead_submission', 'any_lead_submit'],
    srp_ucpq_lead_submission: ['srp_ucpq_lead_submission', 'any_ucpq_lead_submission', 'any_lead_submit'],
    suggestedFilter: 'srp_suggested_filter_click',
    fts_kyw: 'srp_keyword_search_cta_click',
});

const srpConversionClicktypes = [
    'listing',
    'spotlight',
    'alpha',
    'supplemental',
];

const optimizelyTrackWithValue = ({ apiName, updatedWallet = false, useStandardValue = false, optimizelyTrack }) => {
    const value = (updatedWallet && !useStandardValue ? userUpdatedWallatEventValues : eventValues)[apiName];
    optimizelyTrack(apiName, {
        value,
    });
    // eslint-disable-next-line no-console
    console.info(`[Optimizely] Event Fired: ${apiName}.`);

    if (value) {
        // eslint-disable-next-line no-console
        console.info(`[Optimizely] Event Fired: ${apiName} total_value: ${value}.`);
        optimizelyTrack('total_value', {
            value,
        });
    }
};

const validateAndTrackSRPConversion = ({ optimizelyTrack }) => {
    const { href } = global.location;
    const { clickType } = qs.parse(href);
    if (srpConversionClicktypes.includes(clickType)) {
        optimizelyTrackWithValue({ apiName: 'srp_to_vdp_conversion', optimizelyTrack });
    }
};

// Add this container ONLY ONCE per page
function OptimizelyAnalyticsListenerContainer() {
    const {
        optimizely_listener: [enableOpimizelyListener],
    } = useFeatures([
        'optimizely_listener',
    ]);
    const { isBrand } = useBrand();
    const optimizelyTrack = useOptimizelyTrack();
    const currentPageName = useSelector(currentPageNameDuck.selectors.getDuckState);

    const isAtcBranded = isBrand('atc');

    const { onPageEnter } = usePageEvent('optimizelyPageEvent');

    if (currentPageName && isAtcBranded && enableOpimizelyListener) {
        onPageEnter(() => {
            switch (currentPageName) {
                case 'sfp':
                    optimizelyTrack('all-fyc-pages');
                    optimizelyTrack('sfp_pageview');
                    break;
                case 'srp':
                    optimizelyTrack('all-fyc-pages');
                    optimizelyTrackWithValue({ apiName: 'srp_pageview', optimizelyTrack });
                    break;
                case 'vdp':
                    optimizelyTrack('all-fyc-pages');
                    optimizelyTrackWithValue({ apiName: 'vdp_pageview', optimizelyTrack });
                    validateAndTrackSRPConversion({ optimizelyTrack });
                    break;
                default:
                    break;
            }
        });
    }

    // TODO why is there a page component rendered when you scroll down on the vdp?
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {

        function optimizelyAnalyticsClickEventListener(event) {
            const cmp = _get(event, 'detail.cmp') || _get(event, 'detail.data.cmp');

            if (cmp) {
                const updatedWallet = _get(event, 'detail.state.payments.interactions.hasUpdatedWallet', false);
                let apiNameOrNameList = matchEvent(currentPageName, '')[cmp];
                let useStandardValue = false;

                const isClickEmailOnVdp = cmp === 'eml_dlr_lnk' && currentPageName === 'vdp';
                const isSendEmail = cmp === 'snd_eml';

                /* if clicking an emailCTA on the VDP, check which one it is by using the parentId, then update the
                 * apiNameList to generate the correct optimizely id
                 */
                if (isClickEmailOnVdp) {
                    const parentId = _get(event, 'detail.event.target.parentElement.id', '');
                    if (parentId === 'primaryCTA') {
                        apiNameOrNameList = matchEvent(currentPageName, '_primary')[cmp];
                    } else if (parentId === 'secondaryCTA') {
                        apiNameOrNameList = matchEvent(currentPageName, '_secondary')[cmp];
                    }
                }

                // add additional event when submit send email on srp or vdp and use standard value.
                if (isSendEmail) {
                    useStandardValue = true;
                    const includedWalletData = _get(event, 'detail.data.includeWalletData', false);
                    const featureType = _get(event, 'detail.data.vdpType', '');

                    if (includedWalletData && featureType !== 'MyWallet') apiNameOrNameList.push('any_wallet_lead');
                    if (featureType === 'MyWallet') apiNameOrNameList.push('any_nds_lead');
                }

                if (cmp === 'comp_btn') {
                    if (currentPageName === 'vdp') {
                        const targetId = _get(event, 'detail.event.target.id', '');
                        if (targetId === 'btn-comp-sticky') {
                            apiNameOrNameList = matchEvent(currentPageName, 'sticky_')[cmp];
                        } else {
                            apiNameOrNameList = matchEvent(currentPageName, '')[cmp];
                        }
                    } else {
                        apiNameOrNameList = matchEvent(currentPageName, '')[cmp];
                    }
                }

                if (apiNameOrNameList) {
                    const apiNameList = apiNameOrNameList instanceof Array ? apiNameOrNameList : [apiNameOrNameList];
                    apiNameList.forEach((apiName) => optimizelyTrackWithValue({ apiName, updatedWallet, useStandardValue, optimizelyTrack }));
                }
            }
        }

        if (currentPageName && isAtcBranded && enableOpimizelyListener) {
            global.addEventListener('analytics:click', optimizelyAnalyticsClickEventListener);
        }

        return () => {
            global.removeEventListener('analytics:click', optimizelyAnalyticsClickEventListener);
        };
    }, [currentPageName, isAtcBranded, enableOpimizelyListener]);

    return null;
}

export default OptimizelyAnalyticsListenerContainer;
