import qs from 'querystring';

import { fetchJSON } from '@bonnet/next/fetch';

import { createSelector, DuckSelector } from '@atc/modular-redux';

import { resultsDuckCreator } from 'axl-ducks';

import {
    inventoryDuck,
    ownersDuck,
} from '.';

const eLotResultsDuck = resultsDuckCreator({
    store: 'eLot',
    initialState: {
        loading: true,
        initialLoad: true,
        activeResults: [],
    },
}).extend({
    creators: () => ({
        loadELotData: ({ addToCompare = false, excludedListingIds = [], listingCriteria = {} }) => async (dispatch, getState) => {
            const state = getState();
            const query = state?.query;

            const listingMinPrice = Math.round(listingCriteria?.pricingDetail?.salePrice * 0.95);
            const listingMaxPrice = Math.round(listingCriteria?.pricingDetail?.salePrice * 1.05);

            const eLotQueryParams = {
                excludedListingId: excludedListingIds,
                searchRadius: query?.searchRadius || 50,
                zip: listingCriteria?.owner?.location?.address?.zip || query?.zip,
                makeCode: listingCriteria?.makeCode || query?.makeCodeList || query?.makeCode,
                modelCode: listingCriteria?.modelCode || query?.modelCodeList || query?.modelCode,
                startYear: listingCriteria?.year || query?.startYear,
                endYear: listingCriteria?.year || query?.endYear,
                listingTypes: listingCriteria?.listingType || query?.listingTypes,
                maxPrice: listingMaxPrice || query?.maxPrice,
                minPrice: listingMinPrice || query?.minPrice,
            };
            const options = {
                headers: {
                    'X-fwd-svc': 'atc',
                },
                credentials: 'same-origin',
                circuitBreaker: {
                    id: 'eLotCompareListings',
                    timeout: 6000,
                    resetTimeout: 30000,
                    fallback: {},
                },
            };
            const response = await fetchJSON('/rest/lsc/dealerlot/v2/multiple?' + qs.stringify(eLotQueryParams), options);
            const elotListing = response?.listings?.[0];

            if (elotListing) {
                dispatch(ownersDuck.creators.addOwners([elotListing?.owner]));
                dispatch(inventoryDuck.creators.addInventory([elotListing]));
                dispatch(eLotResultsDuck.creators.setActiveResults([elotListing?.id]));
            } else {
                dispatch(eLotResultsDuck.creators.setActiveResults([]));
            }
        },
    }),
    selectors: () => ({
        getELotListingIds: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ activeResults = [] }) => activeResults,
        )),
    }),
});

export default eLotResultsDuck;
