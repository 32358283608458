import React from 'react';

import { ClientOnly } from '@atc/react-client-only';

import { LazyRender } from 'reaxl';

function LazyComponent({
    children,
    fallback = null,
    threshold,
    renderOnScroll = true,
    ...rest
}) {
    return (
        <ClientOnly
            fallback={fallback}
            {...rest}
        >
            {renderOnScroll && (
                <LazyRender
                    fallback={fallback}
                    threshold={threshold}
                >
                    {children}
                </LazyRender>
            )}
            {!renderOnScroll && children}
        </ClientOnly>
    );
}

export default LazyComponent;
