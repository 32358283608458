const SPONSORED_LINK_TEXT = 'SPONSORED';

const FINANCE_CMP = 'apply_crd_vw_wb_btn';
const SCA_LOGO_PATH = '/content/static/img/icon/additionalresources/secure_app.png';

const ICO_CMP = 'ns_tim';
const ICO_TITLE = 'Kelley Blue Book℠ Instant Cash Offer';
const KBB_ALT_TEXT = 'KBB';
const KBB_LOGO = '/content/static/img/icon/logos/kbb-logo-text.svg';
const ICO_LINK_TEXT = 'Use your offer toward the purchase of another vehicle or let the dealer buy it for cash.';
const ICO_LINK_URL = '/trade-in-instant-cash-offer/';

const LOANS_CMP = 'get_loan_txt';
const LOANS_EPN = 'CapitalOne';
const LOANS_TITLE = 'Car Loans';
const LENDER_ALT_TEXT = 'Capital One';
const LENDER_LOGO = '/content/static/img/icon/additionalresources/capital_one.png';
const PRIVATE_SELLER_LOANS_LINK_TEXT = 'Get auto financing with Capital One.';
const LOANS_LINK_TEXT = 'Pre-qualify for financing with Capital One without impacting your credit score.';
const LOANS_URL = 'https://goto.capitalone.com/c/1913588/674667/8420';
const PRIVATE_LOANS_URL = 'https://goto.capitalone.com/c/1913588/674665/8420';

const WARRANTY_TITLE = 'Warranty';
const CARCHEX_ALT_TEXT = 'Carchex';
const CARCHEX_LOGO = '/content/static/img/icon/additionalresources/carchex_logo.png';
const WARRANTY_LINK_TEXT = 'Get a free auto warranty quote today.';
const CARCHEX_URL = 'https://www.carchex.com/auto-warranty/?paid=100578&subpaid=10012';

const CREDIT_REPORT_TITLE = 'Credit Report';
const CREDIT_REPORT_CMP = 'ny_syc';
const CREDIT_REPORT_LINK_TEXT = 'Get Your FICO® Score for Free';
const CREDIT_REPORT_URL = 'https://ad.doubleclick.net/ddm/trackclk/N3973.1360.KELLEYBLUEBOOK/B27554392.333476565;dc_trk_aid=525411378;dc_trk_cid=100510000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=';
const EXPERIAN_ALT_TEXT = 'Experian';
const EXPERIAN_LOGO = '/content/static/img/icon/logos/experian_logo.png';

const ROOT_INSURANCE_CMP = 'get_quote_txt';
const ROOT_INSURANCE_EPN = 'Root';
const ROOT_INSURANCE_TITLE = 'Good drivers save more';
const ROOT_INSURANCE_ALT_TEXT = 'Root Insurance';
const ROOT_INSURANCE_LOGO = '/content/static/img/icon/additionalresources/root_insurance.png';
const ROOT_INSURANCE_LABEL = 'You could save hundreds! Get a free quote now.';
const ROOT_INSURANCE_URL = 'https://quote.joinroot.com/landing?utm_source=cai&utm_medium=integrated&utm_campaign=AT_v1_ad_2&pid=cai&af_channel=integrated&c=AT_v1_ad_2';

const createContentObject = (cmp, epn, alt, src, title, label, href, sponsoredText) => ({
    cmp,
    epn,
    image: {
        alt,
        src,
    },
    title,
    link: {
        label,
        href,
    },
    sponsoredText,
});

const buildFinancingContent = (owner) => {
    const title = 'Finance with ' + owner.name;

    return createContentObject(FINANCE_CMP, '', owner.name, SCA_LOGO_PATH, title, 'Finance with us', owner.dealerFinancingHref, '');
};

const buildIcoContent = (owner) => {
    const linkUrl = owner?.isIcoDealer ? ICO_LINK_URL + `?offerCode=A&dealer_id=${owner.id}` : ICO_LINK_URL;

    return createContentObject(ICO_CMP, '', KBB_ALT_TEXT, KBB_LOGO, ICO_TITLE.toString(), ICO_LINK_TEXT, linkUrl, '');
};

const buildCarLoanContent = (owner) => {
    const isPrivateSeller = owner?.privateSeller;
    const label = isPrivateSeller ? PRIVATE_SELLER_LOANS_LINK_TEXT : LOANS_LINK_TEXT;
    const href = isPrivateSeller ? PRIVATE_LOANS_URL : LOANS_URL;

    return createContentObject(LOANS_CMP, LOANS_EPN, LENDER_ALT_TEXT, LENDER_LOGO, LOANS_TITLE, label, href, SPONSORED_LINK_TEXT);
};

const buildWarrantyContent = () => createContentObject(LOANS_CMP, '', CARCHEX_ALT_TEXT, CARCHEX_LOGO, WARRANTY_TITLE, WARRANTY_LINK_TEXT, CARCHEX_URL, '');

const buildCreditReportContent = () => createContentObject(CREDIT_REPORT_CMP, '', EXPERIAN_ALT_TEXT, EXPERIAN_LOGO, CREDIT_REPORT_TITLE, CREDIT_REPORT_LINK_TEXT, CREDIT_REPORT_URL, '');

const buildRootInsuranceContent = () => createContentObject(ROOT_INSURANCE_CMP, ROOT_INSURANCE_EPN, ROOT_INSURANCE_ALT_TEXT, ROOT_INSURANCE_LOGO, ROOT_INSURANCE_TITLE, ROOT_INSURANCE_LABEL, ROOT_INSURANCE_URL, SPONSORED_LINK_TEXT);

const additionalResourcesFromListing = (owner, isKbb) => {
    const additionalResources = [];

    if (owner?.dealerFinancingHref) {
        additionalResources.push(buildFinancingContent(owner));
    }

    if (isKbb) {
        additionalResources.push(buildWarrantyContent());
        additionalResources.push(buildCreditReportContent());
    } else {
        additionalResources.push(buildIcoContent(owner));
        // For ATC they want to add Credit Report after Instant Cash Offer
        // additional_resources feature is disable for FORD so we can use this !isKbb check for ATC
        additionalResources.push(buildCreditReportContent());
        if (!owner?.hideSponsoredCarLoans) {
            additionalResources.push(buildCarLoanContent(owner));
        }
    }

    additionalResources.push(buildRootInsuranceContent());

    return ({ additionalResources });
};

export default additionalResourcesFromListing;
